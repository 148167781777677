//用户资料
<template>
  <div class="research_center">
    <div class="content">
      <div class="title">
        <p>我的个人资料</p>
      </div>
      <!-- 信息部分 -->
      <div class="information">
        <div class="left_Info">
          <div class="left_tab">
            <div class="stu_name" v-if="regState != -1"></div>
            <div>
              &nbsp;&nbsp;&nbsp;用户ID:&nbsp;&nbsp;&nbsp;{{ id }}

              <span class="copy_id" @click="copyId()">复制</span>
            </div>

            <div class="stu_name">
              用户名称:&nbsp;&nbsp;
              <input type="text" v-model.trim="nickName" class="ip_tx" style="padding-left: 5px" maxlength="6" :onkeyup="ValidateValue()" />
            </div>
            <div class="bd_phone" v-if="pageType != 2">当前登录手机号:&nbsp;&nbsp;&nbsp;{{ tel }}</div>
            <div class="yzm_phone" v-if="pageType == 2">
              绑定手机号: &nbsp;
              <!-- <input
                type="text"
                class="ip_phone"
                style="padding-left: 10px"
                maxlength="11"
                v-model="phonenum"
                placeholder="请输入手机号"
              /> -->
              <el-input type="text" class="new_phone" v-model="phonenum" placeholder="请输入手机号" clearable maxlength="11"></el-input>
              <input type="button" :value="btntxt" :class="disabled ? 'no_btn_phone' : 'btn_phone'" :disabled="disabled" @click="sendcode" />
            </div>
            <div class="xg_phone" v-if="pageType != 2">
              修改登录手机号码:&nbsp;&nbsp;
              <!-- <input
                type="text"
                class="ip_phone"
                style="padding-left: 10px"
                maxlength="11"
                v-model="phonenum"
                placeholder="请输入手机号"
              /> -->

              <el-input type="text" class="new_phone" v-model="phonenum" placeholder="请输入手机号" clearable maxlength="11"></el-input>
              <input type="button" :value="btntxt" :class="disabled ? 'no_btn_phone' : 'btn_phone'" :disabled="disabled" @click="sendcode" />
            </div>
            <div class="yzm_phone">
              手机验证码:&nbsp;&nbsp;
              <!-- <input
                type="text"
                class="yz_phone"
                placeholder="请输入验证码"
                style="padding-left: 10px"
                v-model="userCode"
              /> -->

              <el-input type="text" class="new_phone" v-model="userCode" placeholder="请输入验证码" clearable maxlength="6"></el-input>
            </div>
            <div v-if="pageType == 2 && regState == -1">
              <div class="update_pass">
                设置密码:&nbsp;&nbsp;&nbsp;<input
                  type="text"
                  class="ip_tx"
                  v-model="password"
                  style="padding-left: 6px"
                  placeholder="请输入密码"
                  maxlength="20"
                />
              </div>
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="inspect" v-show="isPass">两次输入密码必须一致</span>
              </div>
              <div>
                确认密码: &nbsp;
                <input
                  type="text"
                  class="ip_tx"
                  @keyup.enter="search"
                  @input="search($event)"
                  style="padding-left: 6px"
                  placeholder="请再次输入密码"
                  maxlength="20"
                />
              </div>
            </div>
            <div class="vx" v-if="pageType == 1">
              微信:&nbsp;&nbsp;&nbsp;
              <!-- <img
                src="../../assets/img/test_demo1.png"
                style="width: 80px; height: 80px"
              /> -->
              <p class="vx_p" @click="regQRcodeWechat">
                <!-- <a href="weixin://dl/business/?t=3lfnmOoORsu">微信</a> -->
                <a>点此扫码绑定微信</a><br />
                第一次绑定请点击
                <a>【关注微信公众号】</a>
              </p>
            </div>

            <!-- 已绑定微信 -->
            <div class="vx" v-if="pageType != 1">
              微信:&nbsp;&nbsp;&nbsp;
              <div class="wechat_img">
                <div>
                  <img :src="WeChatImg" style="width: 80px; height: 80px; border-radius: 40px" />
                  <div class="wechat_name">{{ WeChatName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right_Info">
          <div>
            用户头像:<br />
            <p style="font-size: 15px; color: #bfbfbf">
              上传图片的最佳尺寸:300像素*300像素，其他尺寸会影响页面效果，格式png，jpeg，jpg大小不超过1M
            </p>
            <div>
              <div @mouseover="isMengban = true" @mouseleave="isMengban = false">
                <file-select v-model="file" @reflashData="reflashData"></file-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 保存按钮 -->
      <div class="btn_Preservation">
        <div>
          <input class="btn_save" type="button" value="保 存" @click="btn_preservation()" />
        </div>
      </div>

      <!-- 扫码绑定微信的弹框 -->
      <RegQRcode @close="closeWechatRegDialog" :showDialog="showRegQRcodeDialog" @doWechatReg="doWechatReg" />
    </div>
  </div>
</template>
<script>
import FileSelect from "./fileselection.vue";
import RegQRcode from "@/views/dialog/RegQRcode";
export default {
  data() {
    return {
      id: "",
      tel: "",
      nickName: "",
      isPass: false,
      isMengban: false,
      password: "",
      file: null,
      btntxt: "获取验证码",
      disabled: false,
      time: 0,
      phonenum: "",
      //1未绑定微信,手机号登录  2未绑定手机,微信登录  3已绑定微信与手机
      pageType: 1,
      //微信昵称
      WeChatName: "",
      WeChatImg: "",
      //上传的Loading框
      loading: null,
      //验证码
      userCode: "",
      oldNickName: "",
      showRegQRcodeDialog: false,
      //微信绑定手机号的状态 -1 未注册 1已注册
      regState: "1",
      //密码正则
      passWordReg: /^[a-zA-Z0-9]{6,20}$/
    };
  },
  created() {
    //获取页面状态
    this.initType();
  },
  mounted() {
    //获取个人数据
    this.http();
  },
  methods: {
    ValidateValue() {
      var IllegalString = "\"-[`~!#$^&*()=|{}':;',\\[\\/''].<>/?~！#￥……&*（）——|{}【】‘；：”'。，、？]‘'@…%—+·—_";
      var textboxvalue = this.nickName;
      var index = textboxvalue.length - 1;

      var s = this.nickName.charAt(index);

      if (IllegalString.indexOf(s) >= 0) {
        s = textboxvalue.substring(0, index);
        this.nickName = s;
      }
    },
    copyId() {
      let oInput = document.createElement("input");
      oInput.value = this.id;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.showSuccessMsg("复制成功");
      oInput.remove();
    },
    //关闭微信绑定弹窗
    closeWechatRegDialog() {
      this.showRegQRcodeDialog = false;
    },

    //绑定成功
    doWechatReg() {
      this.closeWechatRegDialog();
      this.initType();
      //同步个人数据
      this.http();
    },
    regQRcodeWechat() {
      // 首次扫码绑定微信
      this.showRegQRcodeDialog = true;
    },
    //上传头像成功刷新数据
    reflashData() {
      this.showSuccessMsg("头像上传成功");
      this.http(1);
    },
    //页面状态
    initType() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.WeChatName != "") {
        if (userInfo.tel != "") {
          //1未绑定微信,手机号登录  2未绑定手机,微信登录  3已绑定微信与手机
          this.pageType = 3;
        } else {
          this.pageType = 2;
        }
      } else {
        this.pageType = 1;
      }
      this.phonenum = "";
      this.userCode = "";
    },
    showUploadLoading() {
      //进行文件的上传并显示Loading效果
      this.loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)"
      });
    },
    //关闭Loading的方法
    closeLoading() {
      //上传成功后关闭loading
      if (!this.isEmpty(this.loading)) {
        this.loading.close();
      }
    },
    //个人资料接口
    async http(type) {
      var _that = this;
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));

      let param = new Object();
      param.type = 5;
      param.uid = userInfo.uid;
      param.token = userInfo.token;

      let res = await this.$http.fetchPost(this.$api.LOGIN, param);

      if (res.data.state == "200") {
        var user = res.data.userInfo;
        console.log(`11111`, user);
        this.nickName = user.name;
        this.oldNickName = user.name;
        this.tel = user.tel;
        this.id = user.uid;
        this.WeChatName = user.WeChatName;
        this.WeChatImg = user.headimgurl;

        //同步昵称头像数据
        if (type == 1) {
          userInfo.user_img = user.user_img;
          userInfo.name = user.name;
          userInfo.tel = user.tel;
          userInfo.WeChatName = user.WeChatName;
          //  userInfo.headimgurl = user.headimgurl;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          this.$bus.emit("updateUserInfo");
        }
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },
    //恢复默认头像
    async defHeadImg() {
      var _that = this;
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));

      let param = new Object();
      param.type = 8;
      param.uid = userInfo.uid;
      param.token = userInfo.token;
      param.state = "def";

      let res = await this.$http.fetchPost(this.$api.LOGIN, param);

      if (res.data.state == "200") {
        this.http();
        this.showSuccessMsg("删除成功,头像恢复默认");
        this.closeLoading();
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.closeLoading();
      }
    },
    //保存按钮
    btn_preservation() {
      console.log("保存");
      if (this.phonenum == "" && this.userCode == "" && this.oldNickName == this.nickName) {
        this.showSuccessMsg("保存成功");
        return;
      }
      if (this.nickName == "") {
        this.showWarnMsg("昵称不能为空");
        return;
      }
      this.showUploadLoading();
      if (this.pageType != 2) {
        //更换绑定手机号 7号接口
        this.changePhoneNumber();
      } else {
        if ((this.phonenum == "" || this.phonenum == null) && (this.userCode == "" || this.userCode == null)) {
          //修改昵称
          this.changeName();
        } else {
          if (this.phonenum == "" || this.phonenum == null) {
            this.showWarnMsg("请输入手机号");

            this.closeLoading();
          } else {
            if (this.userCode == "" || this.userCode == null) {
              this.showWarnMsg("请输入验证码");

              this.closeLoading();
            } else {
              if (!this.passWordReg.test(this.password) && this.regState == -1) {
                this.showWarnMsg("请输入6-20位数字或者字母密码");

                this.closeLoading();
              } else {
                //初次绑定手机号设置密码  10号接口
                this.regPhoneNumber();
              }
            }
          }
        }
      }
    },
    //初次绑定手机号
    async regPhoneNumber() {
      var _that = this;
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));

      let param = new Object();
      param.type = 10;
      param.uid = userInfo.uid;
      param.token = userInfo.token;
      param.tel = this.phonenum;
      param.code = this.userCode;
      param.state = "3";

      if (this.regState == -1) {
        param.p = this.password;
      }

      let res = await this.$http.fetchPost(this.$api.LOGIN, param);

      if (res.data.state == "200") {
        var userInfo = res.data.userInfo;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        this.http();
        this.initType();
        this.showSuccessMsg("手机号绑定成功");
        this.time = 0;
        this.disabled = false;
        this.timer();
        this.closeLoading();
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.closeLoading();
      }
    },
    //修改昵称
    async changeName() {
      var _that = this;
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // type=6&uid=200000028&token0&name=啦啦啦&nickname=hahah哈&age=18&sex=1
      let param = new Object();
      param.type = 6;
      param.uid = userInfo.uid;
      param.token = userInfo.token;
      param.name = this.nickName;
      param.nickname = this.nickName;
      param.age = "0";
      param.sex = "";

      let res = await this.$http.fetchPost(this.$api.LOGIN, param);

      if (res.data.state == "200") {
        this.http(1);
        this.showSuccessMsg("修改成功");
        this.time = 0;
        this.disabled = false;
        this.timer();
        this.initType();
        this.closeLoading();
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.closeLoading();
      }
    },
    //更换绑定手机号
    async changePhoneNumber() {
      var _that = this;
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));

      let param = new Object();
      param.type = 7;
      param.uid = userInfo.uid;
      param.token = userInfo.token;
      if (this.phonenum != null && this.phonenum != "" && this.userCode != "") {
        if (!/^1[3456789]\d{9}$/.test(this.phonenum)) {
          this.closeLoading();
          this.showWarnMsg("手机号格式不对");

          return;
        }
        param.newTel = this.phonenum;
        param.code = this.userCode;
        param.state = "2";
      } else {
        if (this.oldNickName == this.nickName) {
          if (this.phonenum == "" || this.userCode == "") {
            this.showWarnMsg("请输入手机号和验证码");
          } else {
            this.showSuccessMsg("保存成功");
          }
          this.closeLoading();
          return;
        }
      }
      if (this.nickName != null && this.nickName != "") {
        param.name = this.nickName;
      }

      let res = await this.$http.fetchPost(this.$api.LOGIN, param);

      if (res.data.state == "200") {
        this.http(1);
        this.showSuccessMsg("修改成功");
        this.time = 0;
        this.disabled = false;
        this.timer();
        this.initType();
        this.closeLoading();
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.closeLoading();
      }
    },

    //发送验证码按钮
    sendcode() {
      // 校验手机号
      if (this.checkPhoneNumber()) {
        //验证码接口
        this.getCodeHttp(this.phonenum);
      }
    },
    // 校验手机号
    checkPhoneNumber() {
      if (!this.phonenum || this.phonenum == "") {
        this.showWarnMsg("请输入手机号");
        return false;
      }

      if (!/^1[3456789]\d{9}$/.test(this.phonenum)) {
        this.showWarnMsg("手机号格式不对");
        return false;
      }
      return true;
    },
    //获取验证码接口
    async getCodeHttp(phonenum) {
      var _that = this;
      var codeState = "";
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let param = new Object();

      if (this.pageType != 2) {
        //更换绑定手机号
        codeState = "2";
        param.type = 2;
        param.tel = phonenum;
        param.role = "0";
        param.token = userInfo.token;
        param.state = codeState;
      } else {
        //初次绑定手机号设置密码
        param.type = 16;
        param.tel = phonenum;
        param.uid = userInfo.uid;
        param.token = userInfo.token;
        param.tel = phonenum;
        param.state = "3";
      }

      let res = await this.$http.fetchPost(this.$api.LOGIN, param);
      if (res.data.state == 200) {
        //倒计时
        this.time = 300;
        this.disabled = true;
        this.timer();

        this.showSuccessMsg("验证码发送成功!");

        if (this.pageType == 2) {
          //绑定手机号的注册状态 -1 未注册 1已注册
          this.regState = res.data.register_state;
        }
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        //console.log(this.time);
        this.btntxt = this.time + "s";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }
    },
    deleteImg() {
      console.log("弹窗确认删除");
      this.showUploadLoading();
      this.defHeadImg();

      this.file = null;
    },
    search(event) {
      // console.log(event.currentTarget.value);
      if (event.currentTarget.value != this.password) {
        this.isPass = true;
      } else {
        this.isPass = false;
      }
    }
  },
  computed: {},
  components: {
    FileSelect,
    RegQRcode
  }
};
</script>
<style lang="scss" scoped>
.research_center {
  .content {
    width: $common_width;
    height: 100%;
    margin: 0 auto;
    // background: rgb(11, 109, 11);
    display: flex;
    flex-direction: column;
  }
}

.title {
  width: 10%;
  height: 10%;
  p {
    text-align: center;
    line-height: 70px;
  }
}
.information {
  width: 100%;
  height: 70%;
  display: flex;
  flex-wrap: wrap;

  .left_Info {
    width: 70%;
    height: 90%;

    .left_tab {
      justify-content: center;
      margin-left: 150px;
      .update_pass {
        margin-top: 25px;
      }
      .stu_name {
        margin-top: 20px;
      }

      .copy_id {
        color: #43bb61;
        cursor: pointer;
      }
      .none {
        margin-top: 100px;
      }

      .bd_phone {
        margin-top: 20px;
        margin-left: -45px;
      }

      .xg_phone {
        margin-top: 20px;
        margin-left: -63px;
      }
      .yzm_phone {
        margin-top: 20px;
        margin-left: -15px;
      }
      .vx {
        margin-top: 20px;
        height: 80px;
        line-height: 80px;
        display: flex;
        margin-left: 33px;
      }
    }
    .wechat_img {
      justify-content: center;
      text-align: center;
      line-height: 30px;
    }
    .wechat_name {
      line-height: 5px;
      font-size: 14px;
    }
    .vx_p {
      // margin-top: -50px;
      // margin-left: 100px;
      text-align: center;
      line-height: 30px;
      margin-top: 10px;
      margin-left: 30px;
      cursor: pointer;
    }
  }
  .right_Info {
    margin-top: 10px;
    width: 29%;
    height: 90%;
    display: flex;
    flex-direction: column;
    .img_show {
      position: relative;
      margin-top: 10px;
    }
    .stu_img {
      // width: max($numbers: 300px);
      // height: max($numbers: 300px);
      width: 300px;
      height: 300px;
    }
    .img_mengban {
      width: 300px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-size: 10px;
      color: white;
      background: rgba($color: #000000, $alpha: 0.7);
      margin-top: -45px;
      position: absolute;
      border: none;
    }
  }
  .ip_tx {
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    width: 300px;
    height: 30px;
    border-radius: 2px;
    outline: none;
  }

  .ip_phone {
    width: 190px;
    height: 30px;
    border-radius: 2px;
    border: 1px solid #dfdfdf;
    outline: none;
  }
  .new_phone {
    width: 205px;
    height: 25px;
    line-height: 25px;

    /deep/ .el-input__inner {
      height: 35px;
      padding-left: 5px;
      color: #000000;
    }
  }

  .btn_phone {
    width: 100px;
    height: 30px;
    border-radius: 10px;
    border: none;
    margin-left: 10px;
    background-color: #43bb61;
    color: white;
  }
  .no_btn_phone {
    width: 100px;
    height: 30px;
    border-radius: 10px;
    border: none;
    margin-left: 10px;
    background-color: rgb(209, 209, 209);
    color: white;
  }
  .yz_phone {
    width: 190px;
    height: 30px;
    background-color: #edeef0;
    border: 1px solid #dedede;
    border-radius: 5px;
    outline: none;
  }

  .inspect {
    color: red;
  }
  a {
    color: #43bb61;
  }
}

.btn_Preservation {
  width: 100%;
  height: 10%;
  margin-top: 55px;
  margin-bottom: 0px;
  .btn_save {
    color: white;
    background-color: #43bb61;
    text-align: center;
    width: 400px;
    height: 35px;
    border-radius: 10px;
    border: none;
    margin-left: 420px;
    cursor: pointer;
  }
}
</style>
