//绑定微信二维码弹框
<template>
  <div class="create_class_dialog">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="title"
      :width="width"
    >
      <template v-slot:content>
        <div class="create_class_dialog_content">
          <!-- 微信登录弹窗 -->
          <div>
            <!-- <div class="wechat-go-login" @click="setLoginType(0)"></div> -->
            <div class="login-info">
    

              <div class="wechat-code-img" id="qrcode" ref="qrcode"></div>

              <div class="weChat-code-item">
                <span class="weChat-code-text">微信</span>
                <span>扫码绑定</span>
              </div>
              <div class="weChat-code-item">
                <span>第一次绑定请点击</span>
                <span class="weChat-code-text">【关注公众号】</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
import QRCode from "qrcodejs2";
// import loginApi from '@/api/login';

export default {
  data() {
    return {
      //宽度
      width: this.dialogWidth,
      //是否显示确定按钮加载框的标识
      showCommitLoading: false,

      title: "",

      //验证码倒计时
      sendCodeTime: 300,
      codeTimer: "",

      url: "", //二维码地址
      sceneId: "1", //二维码带的参数，用来判断是那个用户扫码的
      timer: null, //定时器
    };
  },
  created() {},
  mounted() {
   
    
  },

  methods: {
    // 获取微信登录二维码
    async getLoginQRCode() {
         var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.url = "";
      window.clearInterval(this.timer);
      // const res = await loginApi.getQRCode();
      let param = new Object();
      param.type = 14;
      param.code = userInfo.tel;
      let res = await this.$http.fetchPost(this.$api.LOGIN, param);
      console.log("微信二维码接口地址", res);
      // if (res.data.state == "200") {
      this.url = res.data.code_url;
      this.sceneId = res.data.scene_id;
      console.log("sceneId", res.data.scene_id);
      this.timer = setInterval(this.getUserByScene, 2000);
      this.$nextTick(() => {
        this.getQrcode(this.url);
      });
      // }
    },
    //根据 sceneId 轮询查询用户信息，判断用户是否登录成功
    async getUserByScene() {
      if (!this.sceneId) return;
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let param = new Object();
      param.type = 15;
      param.uid = userInfo.uid;
      param.token = userInfo.token;
      param.tel = userInfo.tel;
      param.code = this.sceneId;

      let res = await this.$http.fetchPost(this.$api.LOGIN, param);
      console.log("getUser", res);
      if (res.data.state == "200" && res.data.userInfo) {
     
        window.clearInterval(this.timer);
        this.url = "";
        this.sceneId = "";
        //登录成功，处理登录成功逻辑
        this.showSuccessMsg("绑定成功");

        var userInfo = res.data.userInfo;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        //绑定成功刷新数据
        this.$emit("doWechatReg");
      }else if(res.data.state == "203" ){
        this.showErrorMsg(this.getMesage(res.data.state));
        this.showCommitLoading = false;
         window.clearInterval(this.timer);
         this.url = "";
        this.sceneId = "";
           //绑定成功刷新数据
        this.$emit("doWechatReg");
      }
    },
    getQrcode(url) {
      this.$refs.qrcode.innerHTML = ""; // 清除
      new QRCode("qrcode", {
        width: 160,
        height: 160,
        text: url, // 二维码地址
        colorDark: "#000",
        colorLight: "#fff",
      });
      return;
    },

    //关闭弹框
    close() {
        window.clearInterval(this.timer);
      //触发父组件的关闭方法
      this.$emit("close");
      
    },
  },
   watch: {
    showDialog(newValue, oldValue) {
      // console.log(`新值${newValue},旧值${oldValue}`);
      if (newValue != oldValue) {
      if(this.showDialog){
      this.getLoginQRCode();
    }
      }
    },
  },
  computed: {},
  components: {
    CommonDialog,
  },
  props: ["showDialog"],
};
</script>
<style lang="scss" scoped>
.create_class_dialog {
  .create_class_dialog_content {
    display: flex;
    justify-content: center;
    color: black;
    font-size: 10px;
    flex-direction: column;
    .login-top {
      padding-top: 17px;
    }
    //顶部部分
    .top {
      display: flex;
      justify-content: center;
      ::v-deep .el-input {
        width: 80%;
        border: none;
        margin-top: 12px;
      }

      //左右间距
      ::v-deep .el-input__inner {
        padding: 0 15px;
      }

      //获取焦点的颜色
      ::v-deep .el-input__inner:focus {
        border-color: #dcdfe6;
      }

      //移入时的颜色
      ::v-deep .el-input__inner:hover {
        border-color: #dcdfe6;
      }
    }

    .user_agreement {
      font-size: 10px;
      color: #a2a2a2;
      margin-left: 34px;
      margin-top: 53px;
    }
    .user_agreement_detail {
      font-size: 10px;
      color: #28c5aa;
      cursor: pointer;
    }
    //底部的操作按钮
    .bom_action {
      margin-top: 15px;
      height: 38px;
      display: flex;
      justify-content: center;



    }

    .QR_code_login {
      text-align: right;
      width: 100%;

      .icon {
        float: right;
        font-size: 22px;
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .login-info {
      margin-top: 15px;
      margin-left: 34px;
      text-align: center;
      width: 80%;
      font-size: 10px;
    }


    .login-info .el-checkbox__input.is-checked .el-checkbox__inner,
    .login-info .el-checkbox.is-checked .el-checkbox__inner,
    .login-info .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #28c5aa;
      border-color: #28c5aa;
    }

    .login-info .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #000;
    }

    .login-info .register a {
      color: #a2a2a2;
      padding: 0 3px;
      font: size 10px;
      float: right;
    }
  }
  .code-content {
    width: 80%;
    display: flex;
    justify-content: center;
    margin-left: 33px;
    margin-top: 10px;

    .code-input {
      width: 180px;
      margin-right: 10px;
    }

    .code-button {
      width: 85px;
      font-size: 10px;
    }

    .code-button.is-disabled {
      background-color: #eeeeee;
    }
  }
  .default-button {
    height: 40px;
    font-size: 16px;
    background-color: #fff;
    border-color: #40a9fc;
    border: 1;
    padding: 0;
    margin: 0;
    color: #40a9fc;
    font-size: 14px;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
  }
  .go-login {
    display: flex;
    justify-content: center;
    font-size: 10px;
    color: #a2a2a2;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .wechat-go-login {
    display: flex;
    justify-content: right;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 10px;
    color: #28c5aa;
    cursor: pointer;
  }
  .weChat-code-text {
    font-size: 10px;
    color: orangered;
  }
  .weChat-code-item {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .wechat-code-img {
    width: 150px;
    height: 150px;
    margin-left: 20%;
  }
}
</style>