//自定义上传图片按钮样式
<template>
  <el-upload @click.stop.native :auto-upload="true" action="#" :show-file-list="false" class="avatar-uploader" :before-upload="beforeAvatarUpload">
    <input type="button" value="上传头像" class="sc_btn" />
    <!-- <img :src="this.showImgUrl" class="class_img" /> -->
  </el-upload>
</template>
<script>
export default {
  name: "testuploadimg",
  data() {
    return {
      logoImg: null,
      imageUrl: "",
      imgFile: null,
      showImgUrl: require("../../assets/img/test_demo1.png"),
      //页面加载的Loading
      showLoading: false,

      //上传的Loading框
      loading: null
    };
  },

  methods: {
    beforeAvatarUpload(file) {
      //限定只能是JPEG或者PNG格式并且图片大小不超过1MB
      //jpeg的限定
      const isJPG = file.type === "image/jpeg";

      //png格式的限定
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG或者PNG 格式!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return;
      }

      //JPG和PNG并且大小限定2M
      const res = (isJPG || isPNG) && isLt2M;
      if (res) {
        this.imgFile = file;
        //获取需要显示的url
        if (window.FileReader) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = e => {
            //同步下需要显示的图片地址
            this.showImgUrl = e.target.result;
          };
        }
        //开启loading
        this.showUploadLoading();
        //上传文件
        this.uploadFile(file);
      }

      return res;
    },

    showUploadLoading() {
      //进行文件的上传并显示Loading效果
      this.loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)"
      });
    },
    //关闭Loading的方法
    closeLoading() {
      //上传成功后关闭loading
      if (!this.isEmpty(this.loading)) {
        this.loading.close();
      }
    },
    /**
     * 定义上传文件的方法
     * @param type 上传的文件类型 1图片 2视频
     * @param file 上传的文件
     * @returns
     */
    uploadFile(file) {
      this.$http
        .fetchFormDataPost(this.$api.LOGIN, this.getComParams(file))
        .then(res => {
          //关闭弹框
          this.closeLoading();
          if (res.data.state == 200) {
            //上传成功
            this.$emit("reflashData");
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch(err => {
          this.closeLoading();
          this.showErrorMsg("服务器请求异常,上传失败");
        });
    },
    //获取公共请求参数 file上传的文件
    getComParams(file) {
      let param = new FormData();
      param.append("type", 8);
      param.append("uid", this.getUserInfo().uid);
      param.append("token", this.getUserInfo().token);
      param.append("state", "upload");
      param.append("file", file);
      return param;
    }
  }
};
</script>
<style scoped>
/* .file-select > .select-button {
  width: 150px;
  height: 30px;
  border-radius: 10px;
  margin-top: 10px;
  border: none;
  background-color: #1194fe;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
}

.file-select > input[type="file"] {
  display: none;
} */
.class_img {
  width: 300px;
  height: 300px;
  margin-top: 50px;
}
.avatar-uploader {
  position: relative;
}
.sc_btn {
  width: 150px;
  height: 30px;
  border-radius: 10px;
  margin-top: 10px;
  border: none;
  background-color: #43bb61;
  color: white;
  cursor: pointer;
  line-height: 30px;
  justify-content: left;
  position: absolute;
}
</style>
